import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Collapse,
  Modal,
  Input,
  Form,
  theme,
  notification,
  Spin,
  Tooltip,
  message,
  Popconfirm,
  ColorPicker,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  createLevelapi,
  createModuleapi,
  createTarget,
  getLearningapi,
  lessonArchive,
  lessonUnArchive,
  levelArchive,
  levelUnArchive,
  moduleArchive,
  moduleUnArchive,
  updateLevelApi,
  updateModuleApi,
} from "../../store/services/curriculum";

import "../Curriculum/Curriculum.css";
import clock from "../../assets/Clock.png";
import { GoPlus } from "react-icons/go";
import TargetModel from "../Modals/targetModal/targetModals";
import { LuPlus } from "react-icons/lu";
import { IoIosArrowDown } from "react-icons/io";
import { checkRoutePermissions } from "../../utils/permission";
import NotAccess from "../../pages/NotAccess";
import { FiEdit2 } from "react-icons/fi";
import { MdOutlineArchive, MdOutlineUnarchive } from "react-icons/md";
import moment from "moment";

const { Panel } = Collapse;

interface TargetFormValues {
  target: string;
}
interface CurriculumDetailsProps {
  cId: any;
  data?: any;
}

const CurriculumDetails: React.FC<CurriculumDetailsProps> = ({ cId, data }) => {
  const [isLevelModalVisible, setIsLevelModalVisible] = useState(false);
  const [isModuleModalVisible, setIsModuleModalVisible] = useState(false);
  const [isTargetModalVisible, setIsTargetModalVisible] = useState(false);
  const [isModalOpens, setIsModalOpens] = useState(false);
  const [currentModule, setCurrentModule] = useState<string>("");
  const [currentLevel, setCurrentLevel] = useState<string>("");
  const [load, setLoad] = useState(false);
  const [currentLevelId, setCurrentLevelId] = useState<number>();
  const [levels, setLevels] = useState<any>(null);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<string>("main");
  const [loading, setLoading] = useState(false);
  const [hoveredPanel, setHoveredPanel] = useState<number | null>(null);
  const [levelValues, setLevelValues] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const { token } = theme.useToken();
  const subjectId = cId;
  const navigate = useNavigate();
  const [colorRgb, setColorRgb] = useState<any>("rgb(0, 0, 0)");

  const rgbString =
    typeof colorRgb === "string" ? colorRgb : colorRgb?.toRgbString();

  const panelStyle: React.CSSProperties = {
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
  };

  const handleAddTarget = (module: string, level: string) => {
    setCurrentLevel(level);
    setCurrentModule(module);
    setIsModalOpens(true);
    setIsEdit(false);
  };

  const handleAddModule = (levelId: number) => {
    setCurrentLevelId(levelId);
    setIsModuleModalVisible(true);
    setIsEdit(false);
    setColorRgb("rgb(0, 0, 0)");
  };
  const handleEditModule = (module: any) => {
    setCurrentLevelId(module?.moduleId);
    setIsModuleModalVisible(true);
    setIsEdit(true);
    form.setFieldValue("module", module?.name);
    setColorRgb(module?.color);
  };

  const handleEditLevel = (level: any) => {
    setCurrentLevelId(level.levelId);
    setIsLevelModalVisible(true);
    setIsEdit(true);
    setLevelValues(level?.level);
    setColorRgb(level?.color);
  };

  const getData = async () => {
    setIsLoading("main");

    const idAsNumber = parseInt(subjectId ?? "");
    const response = await getLearningapi({ subjectId: idAsNumber });
    if (response) {
      const newLevels = response.newLevelList.map((newLevel: any) => {
        const modules = newLevel.modules.map((module: any) => ({
          name: module.moduleName,
          targets: module.lessonsList.map((lesson: any) => lesson),
          moduleId: module.moduleId,
          status: module.status,
          color: module.color,
        }));
        console.log(modules);

        const { levelId } = newLevel;
        setCurrentLevelId(newLevel.levelId);
        return {
          level: newLevel.levelName,
          status: newLevel.status,
          modules: modules,
          levelId: levelId,
          color: newLevel.color,
        };
      });
      setLevels(newLevels);
    }

    setIsLoading("");
  };

  useEffect(() => {
    getData();
  }, [subjectId]);

  const handleAddLevel = async (values: any, subjectId: string | undefined) => {
    try {
      setLoad(true);

      setIsLoading("level");

      let res;
      if (isEdit) {
        const updateLevels = {
          levelName: values,
          levelId: currentLevelId,
          color: rgbString,
        };
        res = await updateLevelApi(updateLevels);
      } else {
        const idAsNumber = subjectId ? parseInt(subjectId) : undefined;
        const payload: any = {
          levelName: values,
          subjectId: idAsNumber,
          color: rgbString,
        };
        res = await createLevelapi(payload);
      }

      //@ts-ignore

      if (res) {
        form.resetFields();
        setIsLevelModalVisible(false);
        setLoad(false);
        return res;
      } else {
        setIsLoading("");
        setLoad(false);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoad(false);
    }
    // setIsLoading("");
  };

  const handleModuleModalOk = async (values: any) => {
    const moduleData = {
      moduleName: values.module,
      levelId: currentLevelId,
      color: rgbString,
    };

    setIsLoading("module");

    try {
      let res;

      if (isEdit) {
        const updateLevels = {
          moduleName: values.module,
          moduleId: currentLevelId,
          color: rgbString,
        };
        res = await updateModuleApi(updateLevels);
      } else {
        res = await createModuleapi(moduleData);
      }

      if (res) {
        form.setFieldValue("module", undefined);
        setIsModuleModalVisible(false);
        await getData();
      }
    } catch (error) {
      console.error("Error updating or creating module:", error);
    } finally {
      setIsLoading(""); // Reset loading state
    }
  };

  const handleTargetModalOk = async (values: TargetFormValues) => {
    const obj = {
      lessonName: values?.target,
      moduleId: currentModule,
    };

    setIsLoading("target");
    const res = await createTarget(obj);
    if (res) {
      setIsTargetModalVisible(false);
      await getData();
    }
    // setIsLoading("");
  };

  const handleTargetClick = (
    target: string,
    currentLevelData: any,
    currentModuleId: any
  ) => {
    navigate(`/lessondetails/${target}`, {
      state: { currentLevelData, currentModuleId, data },
    });
  };

  const sendData = async () => {
    try {
      const levelToSend = levelValues;
      await handleAddLevel(levelToSend, subjectId);
      getData();
      setIsLevelModalVisible(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const addLevel = () => {
    setIsEdit(false);
    setIsLevelModalVisible(true);
    setLevelValues(`level-${levels?.length + 1}`);
    setColorRgb("rgb(0, 0, 0)");
  };

  const levelArch = async (levelId: any) => {
    setLoading(true)
    const res = await levelArchive({ levelId: levelId });
   if(res) getData();
   setLoading(false)
    
    
  };
  const addUnlevelArch = async (levelId: any) => {
   
    const res = await levelUnArchive({ levelId: levelId });
    getData();
    
  };
  const addArchiveLesson = async (id: any) => {
    setLoading(true)
    const res = await lessonArchive({ lessonId: id });
   if(res)  getData();
   setLoading(false)
  };
  const addunArchiveLesson = async (id: any) => {
    const res = await lessonUnArchive({ lessonId: id });
   
    getData();
  };
  const addArchiveModule = async (id: any) => {
    setLoading(true)
    const res = await moduleArchive({ moduleId: id }); 
   if(res) getData();
   setLoading(false)
  };
  const addunArchiveModule = async (id: any) => {
    const res = await moduleUnArchive({ moduleId: id });
   
    getData();
  };

  const handleLevelModalCancel = () => {
    setColorRgb("rgb(0, 0, 0)");
    setIsLevelModalVisible(false);
  };

  const handleModuleModalCancel = () => {
    setIsModuleModalVisible(false);
    setColorRgb("rgb(0, 0, 0)");
    form.setFieldValue("module", undefined);
  };

  const calculateAllLessonsDuration = (level: any) => {
    let totalDuration = 0;
    level.modules?.forEach((module: any) => {
      module?.targets?.forEach((lesson: any) => {
        totalDuration += Number(lesson?.duration || 0);
      });
    });

    const duration = moment.duration(totalDuration, "minutes");
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    return hours > 0
      ? `${hours} hr ${minutes > 0 ? `${minutes} min` : ""}`
      : `${minutes} min`;
  };

  const calculateAllModuleLessonsDuration = (module: any) => {
    let totalDuration = 0;

    module?.targets?.forEach((lesson: any) => {
      totalDuration += Number(lesson?.duration || 0);
    });

    const duration = moment.duration(totalDuration, "minutes");
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    return hours > 0
      ? `${hours} hr ${minutes > 0 ? `${minutes} min` : ""}`
      : `${minutes} min`;
  };

  return checkRoutePermissions("curriculumDetails") ? (
    <div className="_curriculumDetailsContainer">
      <div className="maindivdetails">
        <div className="_heading">
          {/* <h2>
            {" "}
            <IoIosArrowRoundBack
              style={{ marginBottom: "-7px", cursor: "pointer" }}
              size={35}
              onClick={() => navigate(-1)}
            />{" "}
            Curriculum Details
          </h2> */}
        </div>

        <TargetModel
          isModalOpens={isModalOpens}
          currentModule={currentModule}
          setIsModalOpens={setIsModalOpens}
          getData={getData}
        />

        <div className="levelbtndiv">
          {/* <Button className="black-button" onClick={() => navigate(-1)}>
            <IoIosArrowRoundBack style={{ marginBottom: "-7px" }} size={25} />
            &nbsp;Back
          </Button> */}
          {levels && (
            <Button className="black-button" onClick={() => addLevel()}>
              <LuPlus size={20} />
              &nbsp;Add a level
            </Button>
          )}
          <div></div>
        </div>

        {isLoading === "main" ? (
          <div className="_isLoading">
            <Spin />
          </div>
        ) : (
          <div className="curriculumDetailLevel">
            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <IoIosArrowDown
                  size={20}
                  className={`arrow-icon ${isActive ? "rotate" : ""} ${
                    hoveredPanel ? "hovered" : ""
                  }`}
                />
              )}
              style={{
                background: token.colorBgContainer,
              }}
              accordion
              className="_levelCollapse"
            >
              {levels && levels.length > 0 ? (
                levels?.map((level: any, index: number) => (
                  <Panel
                    header={
                      <p className="panel_hdr_content">
                        {level?.level}{" "}
                        {level?.modules?.length > 0 && (
                          <div className="moduledurationclock">
                            <img src={clock} alt="" className="imgclock" />
                            <p>
                              Lessons Duration:{" "}
                              {calculateAllLessonsDuration(level)}
                            </p>
                          </div>
                        )}
                      </p>
                    }
                    key={index + 1}
                    className="collapsepanel"
                    extra={
                      <>
                        <Tooltip placement="bottom" title={"Add Module"}>
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAddModule(level.levelId);
                            }}
                            className="black-button collapse_action_btn"
                            icon={<GoPlus size={25} />}
                          />
                        </Tooltip>
                        <Tooltip placement="bottom" title={"Edit Level"}>
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditLevel(level);
                            }}
                            className="black-button collapse_action_btn"
                            icon={<FiEdit2 size={22} />}
                          />
                        </Tooltip>
                        <Tooltip>
                          {/* {level.status === "active" ? ( */}
                          <Popconfirm
                            // style={style}
                            title="Archive Level"
                            description="Are you sure add to archive ?"
                            onConfirm={(e) => {
                              e?.stopPropagation();
                              levelArch(level.levelId);
                            }}
                            //  onCancel={(e)=> e?.stopPropagation()}

                            okText="Yes"
                            cancelText="No"
                          >
                            <MdOutlineArchive
                              className="archive_unarchive_btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                // addArchive(record);
                              }}
                            />
                          </Popconfirm>
                          {/* ) : (
                              <MdOutlineUnarchive
                             className="archive_unarchive_btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                addUnlevelArch(level.levelId)
                              }}
                             
                            />
                        

                          )} */}
                        </Tooltip>
                      </>
                    }
                  >
                    <Collapse
                      bordered={false}
                      defaultActiveKey={["1"]}
                      expandIcon={({ isActive }) => (
                        <IoIosArrowDown
                          size={20}
                          className={`arrow-icon ${isActive ? "rotate" : ""} ${
                            hoveredPanel ? "hovered" : ""
                          }`}
                        />
                      )}
                      style={{ background: token.colorBgContainer }}
                      accordion
                      className="_innerCollapse"
                    >
                      {level?.modules?.map((module: any) => (
                        <Panel
                          key={module?.name}
                          header={
                            <p className="panel_hdr_content">
                              {module?.name}
                              <div className="moduledurationclock">
                                <img src={clock} alt="" className="imgclock" />
                                <p>
                                  Module Duration: {module?.targets?.length}{" "}
                                  {module?.targets?.length > 1
                                    ? "lessons"
                                    : "lesson"}
                                </p>
                              </div>
                              <div className="moduledurationclock">
                                <img src={clock} alt="" className="imgclock" />
                                <p>
                                  {module?.targets?.length}{" "}
                                  {module?.targets?.length > 1
                                    ? "Lessons"
                                    : "Lesson"}
                                  , {calculateAllModuleLessonsDuration(module)}
                                </p>
                              </div>
                            </p>
                          }
                          style={panelStyle}
                          className="innerpanel"
                          extra={
                            <>
                              <Tooltip placement="bottom" title={"Add Target"}>
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleAddTarget(
                                      module?.moduleId,
                                      level?.levelId
                                    );
                                  }}
                                  className="black-button collapse_action_btn"
                                  icon={<GoPlus size={25} />}
                                />
                              </Tooltip>
                              <Tooltip placement="bottom" title={"Edit Module"}>
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleEditModule(module);
                                  }}
                                  className="black-button collapse_action_btn"
                                  icon={<FiEdit2 size={22} />}
                                />
                              </Tooltip>
                              <Tooltip>
                                {/* {module.status === "active" ? ( */}
                                <Popconfirm
                                  // style={style}
                                  title="Archive Module"
                                  description="Are you sure add to archive ?"
                                  onConfirm={(e: any) => {
                                    console.log(e);
                                    e.stopPropagation();
                                    addArchiveModule(module.moduleId);
                                  }}
                                  //  onCancel={(e)=> e?.stopPropagation()}

                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <MdOutlineArchive
                                    className="archive_unarchive_btn"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // addArchive(record);
                                    }}
                                  />
                                </Popconfirm>
                                {/* ) : (
                                <MdOutlineUnarchive
                                 className="archive_unarchive_btn"
                                  onClick={(e) =>{ 
                                    e.stopPropagation();
                                    addunArchiveModule(module.moduleId)
                                  }}
                                />
                              )} */}
                              </Tooltip>
                            </>
                          }
                        >
                          <ul className="custom-list">
                            {module?.targets?.map((lesson: any, i: number) => {
                              return (
                                <div className="lesson_list_item">
                                  <li
                                    key={i}
                                    onClick={() =>
                                      lesson.status === "active" &&
                                      handleTargetClick(
                                        lesson?.lessonId,
                                        level,
                                        module.moduleId
                                      )
                                    }
                                  >
                                    {lesson?.lessonName}
                                  </li>{" "}
                                  {/* {lesson.status === "active" ? ( */}
                                  <Popconfirm
                                    // style={style}
                                    title="Archive Lesson"
                                    description="Are you sure add to archive ?"
                                    onConfirm={(e) => {
                                      e?.stopPropagation();
                                      addArchiveLesson(lesson.lessonId);
                                    }}
                                    //  onCancel={(e)=> e?.stopPropagation()}

                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <MdOutlineArchive
                                      className="lesson_archive_unarchive_btn"
                                      onClick={() => {
                                        // addArchive(record);
                                      }}
                                    />
                                  </Popconfirm>
                                  {/* ) : (
                                    <MdOutlineUnarchive
                                     className="lesson_archive_unarchive_btn"
                                      onClick={(e) =>{ 
                                        addunArchiveLesson(lesson.lessonId);
                                        e.stopPropagation();
                                      }}
                                    />
                                  )} */}
                                </div>
                              );
                            })}
                          </ul>
                        </Panel>
                      ))}
                    </Collapse>
                  </Panel>
                ))
              ) : (
                <div className="module_curriculum">No Data Found</div>
              )}
            </Collapse>
          </div>
        )}

        {/* <Modal
          title="Add Target"
          open={isTargetModalVisible}
          onCancel={() => setIsTargetModalVisible(false)}
          footer={null}
          className="custom-modal"
          centered
        >
          <Form onFinish={handleTargetModalOk}>
            <Form.Item
              name="target"
              label="Target"
              rules={[{ required: true, message: "Please input the target!" }]}
            >
              <Input />
            </Form.Item>
            <Button
              loading={isLoading === "target"}
              disabled={isLoading === "target"}
              htmlType="submit"
              style={{
                backgroundColor: "var(--secondary-color)",
                color: "white",
              }}
            >
              Add Target
            </Button>
          </Form>
        </Modal> */}

        {levels && (
          <Modal
            title={
              <span className="levelTitle">
                {isEdit ? "Edit Level" : "Add Level"}
              </span>
            }
            open={isLevelModalVisible}
            onCancel={handleLevelModalCancel}
            footer={null}
            className="custom-modal"
            centered
          >
            {/* <Form
            layout={"vertical"}
            onFinish={(values) => handleAddLevel(values, subjectId)}
          >
            <Form.Item
              name="levelName"
              label="Level"
              className="level"
              rules={[
                { required: true, message: "Please input the level name!" },
              ]}
            >
              <Input placeholder="Add level" />
            </Form.Item>
            <div className="btn-parent">
              <Button
                onClick={() => setIsLevelModalVisible(false)}
                style={{ backgroundColor: "white", color: "black" }}
              >
                Cancel
              </Button>
              <Button
                loading={isLoading === "level"}
                disabled={isLoading === "level"}
                htmlType="submit"
                style={{
                  backgroundColor: "var(--secondary-color)",
                  color: "white",
                }}
              >
                Add Level
              </Button>
            </div>
          </Form> */}
            <label className="label_curriculum">
              {" "}
              <span className="required_icon_input">*</span> Level
            </label>
            <input
              value={levelValues}
              className="curriDetails"
              type="text"
              placeholder="Add Level"
              onChange={(e) => {
                setLevelValues(e.target.value);
              }}
            />
            <label className="label_curriculum">Pick Color</label>
            <br />
            <ColorPicker
              className="color_picker"
              format="rgb"
              value={colorRgb}
              onChange={setColorRgb}
            />
            <br />
            <br />
            <div className="btn-parent">
              <Button
                onClick={() => setIsLevelModalVisible(false)}
                disabled={load}
                style={{ backgroundColor: "white", color: "black" }}
              >
                Cancel
              </Button>
              <Button
                loading={load}
                disabled={load}
                // htmlType="submit"
                onClick={sendData}
              >
                {isEdit ? " Edit Level" : " Add Level"}
              </Button>
            </div>
          </Modal>
        )}

        <Modal
          title={
            <span className="levelTitle">
              {isEdit ? "Edit Module" : "Add Module"}
            </span>
          }
          open={isModuleModalVisible}
          onCancel={handleModuleModalCancel}
          footer={null}
          className="custom-modal"
          centered
        >
          <Form form={form} onFinish={handleModuleModalOk} layout="vertical">
            <Form.Item
              name="module"
              label="Module"
              rules={[
                { required: true, message: "Please input the module name!" },
              ]}
            >
              <Input placeholder="Add a Module" />
            </Form.Item>
            <Form.Item label="Pick Color">
              <ColorPicker
                className="color_picker"
                format="rgb"
                value={colorRgb}
                onChange={setColorRgb}
              />
            </Form.Item>

            <div className="btn-parent">
              <Button
                onClick={() => {
                  setIsModuleModalVisible(false);
                  form.setFieldValue("module", undefined);
                }}
                disabled={isLoading === "module"}
                style={{ backgroundColor: "white", color: "black" }}
              >
                Cancel
              </Button>
              <Button
                loading={isLoading === "module"}
                disabled={isLoading === "module"}
                htmlType="submit"
              >
                {isEdit ? "Edit Module" : "Add Module"}
              </Button>
            </div>
          </Form>
        </Modal>
      </div>
    </div>
  ) : (
    <NotAccess />
  );
};

export default CurriculumDetails;
