import { useEffect, useState } from "react";
import { Button, Input, Modal, Space, Spin, Tooltip } from "antd";
import {
  IoIosArrowRoundBack,
  IoIosSearch,
  IoMdArrowBack,
  IoMdArrowForward,
} from "react-icons/io";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import { getSubjectByClass } from "../../../store/services/subject";
import { firstLetterCap } from "../../../utils";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setActiveFilter,
  setActiveSubject,
  setCurrentIndex,
  setCurrentLevel,
  setLevels,
} from "../../../store/slices/Classes/classSlice";
import { getAllStudentsByClassSubject } from "../../../store/services/student";
import {
  createProgress,
  getLevelBySubject,
  getStudentProgressByLevel,
  updateStudentLevel,
} from "../../../store/services/class";
import { setStudentsByClassSubject } from "../../../store/slices/students/studentSlice";
import {
  LessonType,
  LevelType,
  ModuleType,
  ResetStatusType,
  StatusType,
} from "../../../types/interface/level";
import { SubjectType } from "../../../types/interface/subject";
import ProgressNoteModal from "../../../components/Modals/ProgressNoteModal";
import { BiRefresh } from "react-icons/bi";

import { setActiveProcess } from "../../../store/slices/Classes/activeSlice";
import { useSelector } from "react-redux";
import useBackButtonBlocker from "../../../Hook/useHistoryBlocker";
import { FiEdit2 } from "react-icons/fi";

const DesktopView = () => {
  const { subjects, activeSubject, levels, activeFilter, currentLevel } =
    useAppSelector((state) => state.class);
  const { studentsByClassSubject } = useAppSelector((state) => state.student);
  console.log("studentsByClassSubject", studentsByClassSubject);
  const [isLoading, setIsLoading] = useState<string>("");
  const [updateStatus, setUpdateStatus] = useState<Array<StatusType>>([]);
  const [noteModal, setNoteModal] = useState<boolean>(false);
  const [noteModalData, setNoteModalData] = useState<any>(null);
  const [categoryAdded, setCategoryAdded] = useState(false);
  const [selectedLevels, setSelectedLevels] = useState(
    levels.length ? [levels[0]] : []
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { classId } = useParams();

  const isActive = useSelector((state: any) => state.active.activeProcessing);

  useEffect(() => {
    _getInitial();

    const cleanup = () => {
      dispatch(setActiveSubject(null));
    };

    return cleanup;
  }, [classId, categoryAdded]);

  const handleProgressAdded = () => {
    setCategoryAdded((prev) => !prev);
  };

  useEffect(() => {
    if (levels.length && selectedLevels.length === 0) {
      setSelectedLevels([levels[0]]);
      handleChangeLevel(levels[0].levelId);
    }
  }, [levels]);

  // useEffect(() => {
  //   console.log("======>", currentLevel);
  // }, [currentLevel]);

  // useBackButtonBlocker(isActive, "Are you sure you want to leave this page?");

  const _getInitial = async () => {
    dispatch(setStudentsByClassSubject([]));
    dispatch(setLevels([]));
    dispatch(setCurrentLevel([]));
    if (classId) {
      setIsLoading("initial");
      const _subjects = await getSubjectByClass({ classId: Number(classId) });
      if (_subjects?.subjects?.length > 0) {
        dispatch(setActiveSubject(_subjects?.subjects?.[0]));
        const subjectId = _subjects?.subjects?.[0]?.subjectId;
        await getAllStudentsByClassSubject({
          classId: Number(classId),
          subjectId,
        });
        const _level: any = await getLevelBySubject({ subjectId });
        if (_level?.length > 0)
          await getStudentProgressByLevel({
            levelId: _level?.[0]?.levelId,
          });
      }
      setIsLoading("");
    }
  };

  const _getData = async (_subject: SubjectType) => {
    dispatch(setStudentsByClassSubject([]));
    dispatch(setLevels([]));
    dispatch(setCurrentLevel([]));
    if (classId && _subject) {
      setIsLoading("level");
      const subjectId = _subject?.subjectId;
      await getAllStudentsByClassSubject({
        classId: Number(classId),
        subjectId,
      });
      const _level = await getLevelBySubject({ subjectId });
      if (_level?.length > 0)
        await getStudentProgressByLevel({
          levelId: _level?.[0]?.levelId,
        });
      setIsLoading("");
    }
  };

  const _getProgress = async (levelId: number) => {
    setIsLoading("level");
    await getStudentProgressByLevel({
      levelId,
    });
    setIsLoading("");
  };

  const handleChangeLevel = async (levelId: number) => {
    try {
      const selectedLevel = levels.find((lev: any) => lev.levelId === levelId);

      // Check if the clicked level is already selected
      if (selectedLevel) {
        const index = selectedLevels.findIndex(
          (lev) => lev.levelId === levelId
        );

        // If the level is found and it's not the last one
        if (index !== -1 && index < selectedLevels.length - 1) {
          // Move all levels after the clicked level back to the right container
          const newSelectedLevels = selectedLevels.slice(0, index + 1);
          const levelsToReturn = selectedLevels.slice(index + 1);

          // Update the selected levels
          setSelectedLevels(newSelectedLevels);

          // Add the levels back to available levels (if necessary)
          setLevels((prevLevels: any) => [...prevLevels, ...levelsToReturn]);
        } else if (!selectedLevels.includes(selectedLevel)) {
          // If it's a new level being added
          setSelectedLevels([...selectedLevels, selectedLevel]);
        }
      }

      // Your existing loading logic
      setIsLoading("level");
      dispatch(setCurrentLevel([]));
      await getStudentProgressByLevel({ levelId });
      setIsLoading("");
    } catch (err) {
      setIsLoading("");
      dispatch(setCurrentLevel([]));
    }
  };

  const getColor = (value: string | null | undefined) => {
    switch (value) {
      case "pass":
        return "rgb(83, 194, 37)";
      case "fail":
        return "rgba(234, 67, 54, 1)";
      case "in-progress":
        return "rgba(255, 217, 102, 1)";
      default:
        return "#fff";
    }
  };

  const _handleStatusUpdate = (obj: StatusType) => {
    console.log("🚀 ~ obj:", obj);
    const temp: Array<StatusType> = [...updateStatus];
    const index = temp?.findIndex(
      (v: any) =>
        v?.lessonId === obj?.lessonId &&
        v?.studentId === obj?.studentId &&
        v?.levelId === obj?.levelId &&
        v?.moduleId === obj?.moduleId &&
        v?.subjectId === obj?.subjectId &&
        v?.classId === obj?.classId
    );
    if (index !== -1) {
      temp.splice(index, 1, obj);
    } else {
      temp.push(obj);
    }
    setUpdateStatus(temp);
    dispatch(setActiveProcess(true));
  };

  const _handleStatusReset = (obj: ResetStatusType) => {
    const temp: Array<StatusType> = [...updateStatus];
    const index = temp?.findIndex(
      (v: any) =>
        v?.lessonId === obj?.lessonId &&
        v?.studentId === obj?.studentId &&
        v?.levelId === obj?.levelId &&
        v?.moduleId === obj?.moduleId &&
        v?.subjectId === obj?.subjectId &&
        v?.classId === obj?.classId
    );
    if (index !== -1) {
      temp.splice(index, 1);
    }
    setUpdateStatus(temp);
    dispatch(setActiveProcess(true));
  };

  const handleSaveProgress = async () => {
    setIsLoading("progress");

    console.log("updateStatus", classId, activeSubject);

    const res = await createProgress({ progress: updateStatus });
    if (res) {
      const levelRes = await updateStudentLevel({
        classId: classId,
        subjectId: activeSubject?.subjectId,
      });

      if (levelRes) {
        updateStatus.length > 0 &&
          (await _getProgress(updateStatus[0].levelId));
        setUpdateStatus([]);
        dispatch(setActiveProcess(false));
      }
      await getAllStudentsByClassSubject({
        classId: Number(classId),
        subjectId: activeSubject?.subjectId,
      });
    }
    setIsLoading("");
  };

  const handleOpenNoteModal = (obj: any) => {
    console.log(obj);
    setNoteModal(true);
    setNoteModalData(obj);
  };
  const handleCancelNoteModal = () => {
    setNoteModal(false);
    setNoteModalData(null);
  };
  const moveBack = () => {
    if (isActive) {
      const confirmNavigation = window.confirm(
        "you have unsaved progress are you sure you want to navigate"
      );
      if (!confirmNavigation) {
        return; // Prevent navigation if the user cancels
      }
      dispatch(setActiveProcess(false));
    } else {
      navigate(-1);
    }
  };
  const moveTabs = (subj: any) => {
    if (isActive) {
      const confirmNavigation = window.confirm(
        "you have unsaved progress are you sure you want to navigate"
      );
      if (!confirmNavigation) {
        return; // Prevent navigation if the user cancels
      }
      dispatch(setActiveProcess(false));
      setUpdateStatus([]);
    } else {
      setSelectedLevels([]);
      _getData(subj);
      dispatch(setActiveSubject(subj));
    }
  };

  return (
    <div className="_progressContainer">
      <div className="_progressHeader">
        <div className="_headingBox">
          <h2>
            {" "}
            <IoIosArrowRoundBack
              style={{ marginBottom: "-7px", cursor: "pointer" }}
              size={35}
              onClick={() => moveBack()}
            />{" "}
            Classes
          </h2>
          <p>Review and manage the classes.</p>
        </div>
        {/* <Input
          className="_searchInput"
          placeholder="Search..."
          prefix={<IoIosSearch />}
        /> */}
        {/* <div className="_buttonGroup">
          <Button
            type="primary"
            onClick={() => dispatch(setActiveFilter("full-class"))}
            className={activeFilter === "full-class" ? "active" : ""}
          >
            Full class
          </Button>
          <Button
            type="primary"
            onClick={() => dispatch(setActiveFilter("groups"))}
            className={activeFilter === "groups" ? "active" : ""}
          >
            Groups
          </Button>
        </div> */}
        {/* <Button
          className="_addClassBtn"
          type="primary"
          icon={
            <FiPlus color="#fff" size={20} style={{ marginBottom: "-5px" }} />
          }
        >
          Add a Class
        </Button> */}
      </div>

      <div className="_subjectContainer">
        {subjects?.map((subj) => {
          return (
            <div
              onClick={() => {
                // currentLevel.length > 0 &&
                moveTabs(subj);
              }}
              key={subj?.subjectId}
              className={
                activeSubject?.subjectId === subj?.subjectId
                  ? "_subjectButtonActive"
                  : "_subjectButton"
              }
              // style={{cursor:currentLevel.length > 0 ? "pointer" : "not-allowed"}}
              style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
            >
              <p>{firstLetterCap(subj?.subjectName)}</p>
            </div>
          );
        })}
      </div>

      {isLoading === "initial" ? (
        <div className="_loadingSpinner">
          <Spin />
        </div>
      ) : subjects?.length > 0 ? (
        <section>
          <div className="_userSection">
            <div className="_top">
              {/* ****************************************************************** */}
              {updateStatus?.length > 0 && (
                <>
                  <Button
                    loading={isLoading === "progress"}
                    onClick={handleSaveProgress}
                  >
                    Save Progress
                  </Button>
                  <Button
                    onClick={() => {
                      setUpdateStatus([]);
                      dispatch(setActiveProcess(false));
                    }}
                  >
                    Clear
                  </Button>
                </>
              )}
            </div>

            {/* {studentsByClassSubject?.map((v, i) => { */}

            {studentsByClassSubject
              // ?.filter(
              //   (student: any) =>
              //     Number(student?.levelId) >= Number(currentLevel[0]?.levelId)
              // )
              ?.map((v, i) => {
                const bg = i % 2 == 1;
                return (
                  <div
                    className="_userBox"
                    key={i}
                    style={{
                      backgroundColor: bg ? "rgba(250, 251, 253, 1)" : "#fff",
                    }}
                  >
                    <p>
                      {firstLetterCap(v?.firstName)}{" "}
                      {firstLetterCap(v?.lastName)}
                    </p>
                  </div>
                );
              })}
          </div>
          <div className="_progressSection">
            <div className="_levelContainer">
              {selectedLevels.map((v: any, i) => (
                <div
                  key={i}
                  className="_levelBox"
                  style={{ backgroundColor: `${v?.color}` }}
                  onClick={() => handleChangeLevel(v.levelId)}
                >
                  <span>{v?.levelName}</span>
                </div>
              ))}
            </div>

            <div className="_levelContent">
              <div
                className="_lcHeader"
                style={{
                  backgroundColor:
                    selectedLevels.length > 0
                      ? `${selectedLevels[selectedLevels.length - 1]?.color}`
                      : "transparent", // Use "transparent" or any fallback color you want
                }}
                // style={{
                //   backgroundColor: `rgb(${
                //     levels?.find(
                //       (lev: LevelType) =>
                //         lev?.levelId === currentLevel?.[0]?.levelId
                //     )?.color
                //   })`,
                // }}
              >
                <p>
                  {selectedLevels[selectedLevels.length - 1]?.levelName}
                  {/* {
                    levels?.find(
                      (lev: LevelType) =>
                        lev?.levelId === currentLevel?.[0]?.levelId
                    )?.levelName
                  } */}
                </p>
              </div>

              {isLoading === "level" ? (
                <div className="_loadingSpinner2">
                  <Spin />
                </div>
              ) : (
                <>
                  {currentLevel?.length ? (
                    <>
                      <div className="_levelContentBody">
                        {currentLevel?.map((module: ModuleType, i: number) => {
                          return (
                            <div key={i} className="_hCard">
                              <div
                                className="_hCardHeader"
                                style={{
                                  backgroundColor: module?.color,
                                }}
                              >
                                <p>{firstLetterCap(module?.moduleName)}</p>
                              </div>
                              <div className="_hCardContent">
                                {module?.lessons?.map(
                                  (lesson: LessonType, i: number) => {
                                    const rgbToRgba = (
                                      rgb: any,
                                      opacity: any
                                    ) => {
                                      const [r, g, b] = rgb.match(/\d+/g);
                                      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
                                    };
                                    return (
                                      <div key={i} className="_vCard">
                                        <div
                                          className="_vCardHeader"
                                          style={{
                                            backgroundColor: rgbToRgba(
                                              module?.color,
                                              0.4
                                            ),
                                          }}
                                        >
                                          <p>
                                            {lesson?.lessonName?.length > 35
                                              ? `${lesson?.lessonName?.slice(
                                                  0,
                                                  35
                                                )}...`
                                              : lesson?.lessonName}
                                          </p>
                                        </div>
                                        {studentsByClassSubject
                                          // ?.filter(
                                          //   (student: any) =>
                                          //     Number(student?.levelId) >=
                                          //     Number(currentLevel[0]?.levelId)
                                          // )
                                          ?.map((v: any, i: number) => {
                                            const _studentForUpdate =
                                              updateStatus?.find(
                                                (x) =>
                                                  x?.lessonId ===
                                                    lesson?.lessonId &&
                                                  x?.studentId ===
                                                    v?.studentId &&
                                                  x?.levelId ===
                                                    module?.levelId &&
                                                  x?.moduleId ===
                                                    module?.moduleId
                                              );

                                            const _student =
                                              lesson?.progress?.find(
                                                (x) =>
                                                  v?.studentId == x?.studentId
                                              );
                                            const _obj: any = {
                                              lessonId: lesson?.lessonId,
                                              studentId: v?.studentId,
                                              levelId: module?.levelId,
                                              moduleId: module?.moduleId,
                                              subjectId:
                                                activeSubject?.subjectId,
                                              classId: classId,
                                            };

                                            const isPassButton =
                                              _studentForUpdate?.status ===
                                                "pass" ||
                                              _student?.status === "pass";
                                            const isFailButton =
                                              _studentForUpdate?.status ===
                                                "fail" ||
                                              _student?.status === "fail";
                                            const isInProgressButton =
                                              _studentForUpdate?.status ===
                                                "in-progress" ||
                                              _student?.status ===
                                                "in-progress";

                                            return (
                                              <Tooltip
                                                placement="top"
                                                overlayStyle={{
                                                  width: "fit-content",
                                                  minWidth: "fit-content",
                                                  maxWidth: "fit-content",
                                                }}
                                                title={
                                                  <div className="_statusButtonBox">
                                                    {/* {!isPassButton && ( */}
                                                    <Button
                                                      style={{
                                                        border: isPassButton
                                                          ? "2px solid white"
                                                          : "none",
                                                        background:
                                                          getColor("pass"),
                                                      }}
                                                      onClick={() =>
                                                        _handleStatusUpdate({
                                                          ..._obj,
                                                          status: "pass",
                                                        })
                                                      }
                                                    >
                                                      complete
                                                    </Button>
                                                    {/* )} */}
                                                    {/* {!isFailButton && ( */}
                                                    <Button
                                                      style={{
                                                        border: isFailButton
                                                          ? "2px solid white"
                                                          : "none",
                                                        background:
                                                          getColor("fail"),
                                                      }}
                                                      onClick={() =>
                                                        _handleStatusUpdate({
                                                          ..._obj,
                                                          status: "fail",
                                                        })
                                                      }
                                                    >
                                                      incomplete
                                                    </Button>
                                                    {/* )} */}
                                                    {/* {!isInProgressButton && ( */}
                                                    <Button
                                                      style={{
                                                        border:
                                                          isInProgressButton
                                                            ? "2px solid white"
                                                            : "none",
                                                        background:
                                                          getColor(
                                                            "in-progress"
                                                          ),
                                                      }}
                                                      onClick={() =>
                                                        _handleStatusUpdate({
                                                          ..._obj,
                                                          status: "in-progress",
                                                        })
                                                      }
                                                    >
                                                      In Progress
                                                    </Button>
                                                    {/* )} */}
                                                    {/* {_studentForUpdate?.status && ( */}
                                                    <BiRefresh
                                                      size={30}
                                                      color={"#fff"}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        _handleStatusReset({
                                                          ..._obj,
                                                        });
                                                      }}
                                                    />
                                                    {/* )} */}
                                                    <FiEdit2
                                                      size={22}
                                                      color={"#fff"}
                                                      style={{
                                                        cursor:
                                                          _student?.progressId
                                                            ? "pointer"
                                                            : "not-allowed",
                                                      }}
                                                      onClick={() => {
                                                        _student?.progressId &&
                                                          handleOpenNoteModal({
                                                            value: v,
                                                            studentId:
                                                              v?.studentId,
                                                            studentName: `${v?.firstName} ${v?.lastName}`,
                                                            imageUrl:
                                                              v?.imageUrl,
                                                            className:
                                                              v?.className,
                                                            levelId:
                                                              module?.levelId,
                                                            // moduleId: module?.moduleId,
                                                            subjectId:
                                                              v?.subjectId,
                                                            subjectName:
                                                              subjects?.find(
                                                                (x) =>
                                                                  x?.subjectId ===
                                                                  v?.subjectId
                                                              )?.subjectName,
                                                            lessonId:
                                                              lesson?.lessonId,
                                                            lessonName:
                                                              lesson?.lessonName,
                                                            progress:
                                                              _studentForUpdate ||
                                                              _student ||
                                                              module?.moduleId,
                                                          });
                                                      }}
                                                    />
                                                  </div>
                                                }
                                              >
                                                <div
                                                  key={i}
                                                  className="_studentColorBox"
                                                  style={{
                                                    backgroundColor: getColor(
                                                      _studentForUpdate?.status ||
                                                        _student?.status
                                                    ),
                                                  }}
                                                  onDoubleClick={() =>
                                                  {_student?.progressId && (
                                                    handleOpenNoteModal({
                                                      value: v,
                                                      studentId: v?.studentId,
                                                      studentName: `${v?.firstName} ${v?.lastName}`,
                                                      imageUrl: v?.imageUrl,

                                                      className: v?.className,

                                                      levelId: module?.levelId,

                                                      subjectId: v?.subjectId,
                                                      subjectName:
                                                        subjects?.find(
                                                          (x) =>
                                                            x?.subjectId ===
                                                            v?.subjectId
                                                        )?.subjectName,

                                                      lessonId:
                                                        lesson?.lessonId,
                                                      lessonName:
                                                        lesson?.lessonName,

                                                      progress:
                                                        _studentForUpdate ||
                                                        _student ||
                                                        module?.moduleId,
                                                    })
                                                  )}
                                                  }
                                                >
                                                  {_student?.notes && (
                                                    <div
                                                      className="_cornerNoteSign"
                                                      style={{
                                                        borderTop: `10px solid ${
                                                          isPassButton
                                                            ? "rgba(8, 93, 58, 1)"
                                                            : isInProgressButton
                                                            ? "rgba(181, 71, 8, 1)"
                                                            : "transparent"
                                                        }`,
                                                      }}
                                                    />
                                                  )}
                                                </div>
                                              </Tooltip>
                                            );
                                          })}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <div className="_noDataFound">
                      <p>No data found!</p>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="_levelContainer">
              {levels
                // @ts-ignore
                ?.filter(
                  (lev: any) =>
                    !selectedLevels.some(
                      (selected) => selected.levelId === lev.levelId
                    )
                ) // Show only non-selected levels
                .map((v: any, i: any) => (
                  <div
                    key={i}
                    className="_levelBox"
                    style={{
                      backgroundColor: `${v?.color}`,
                      cursor: isLoading ? "not-allowed" : "pointer",
                    }}
                    onClick={() => !isLoading && handleChangeLevel(v.levelId)}
                  >
                    <span>{v?.levelName}</span>
                  </div>
                ))}
            </div>
          </div>
        </section>
      ) : (
        <div className="_emptyText">
          <p>No subject found!</p>
        </div>
      )}
      <ProgressNoteModal
        open={noteModal}
        close={handleCancelNoteModal}
        data={noteModalData}
        onProgressAdded={handleProgressAdded}
      />
    </div>
  );
};

export default DesktopView;
