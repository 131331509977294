import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Input,
  Progress,
  Select,
  Table,
  Modal,
  Form,
  Tag,
  Space,
  Tooltip,
  notification,
  Flex,
  Spin,
  Popconfirm,
} from "antd";
import "../styles/pages/students.css";
import { MdAssignment } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import { RiExpandUpDownLine } from "react-icons/ri";
import { FiEdit2, FiEdit } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
// @ts-ignore
import addstudent from "../assets/addstudent.png";
import CustomPagination from "../components/Pagination/CustomPagination";
import GHeader from "../components/GeneralHeader/GHeader";
import {
  assignStudents,
  deleteStudent,
  deleteStudentSubj,
  filterStudents,
  getAllStudents,
  getAllStudentsByTeacher,
  getSubject,
  searchStundentByTeacher,
} from "../store/services/student";
import StudentModal from "../components/Modals/studentModal/StudentModal";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import { checkRoutePermissions } from "../utils/permission";
import NotAccess from "./NotAccess";
import { getAllClass, getAllClasses } from "../store/services/teacher";
import { debounce } from "lodash";
import SearchableDropdown from "../components/SearchableDropdown";
import { GoPlus } from "react-icons/go";
import StudentSignupModal from "../components/Modals/studentModal/StudentSignupModal";
import img from "../assets/attachment.png";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Loading from "../components/Loading/Loading";

interface Subject {
  id: number;
  classId: number;
  studentId: number;
  subjectId: number;
  subjectName: string;
  className?: string;
  color?: string;
}
interface StudentSubject {
  classId: number;
  className: string;
  color: string;
  curriculumId: number;
  id: number;
  levelId: number;
  levelName: string;
  studentId: number;
  subjectId: number;
  subjectName: string;
}

interface Student {
  id: number;
  key: number;
  avatar: string;
  name: string;
  lastname: string;
  Lastname?: string;
  class: string;
  email: string;
  number: string;
  progress: number;
  subjectsProgress: number;
  subjectNames: string;
  subjects: StudentSubject[];
}

interface TeachersProps {
  id: number;
  imageUrl: string;
  firstName: string;
  salutation: string;
  LastName: string;
  className: string;
  email: string;
  lastName?: string;
  phoneNumber: string;
  progress: number;
  subjectsProgress: number;
  subjects: Subject[];
  classId?: number;
}

const Teachers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [studentData, setStudentData] = useState<TeachersProps[]>([]);
  const [singleStudentData, setSingleStudentData] = useState<
    null | number | string
  >(null);
  const [singleStudentId, setSingleStudentId] = useState([]);
  const [classes, setClasses] = useState([]);
  const [classValue, setClassValue] = useState<null | number | string>(null);
  const [count, setCount] = useState<number>(0);
  const [data, setData] = useState<Student>();
  const [classesId, setClassesId] = useState("");
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [subjectList, setSubjectList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [SubjectValue, setSubjectValue] = useState([]);
  const [classId, setClassId] = useState<null | number>(null);
  const [load, setLoad] = useState(false);
  const [page, setPages] = useState(1);
  const [perPage, setPerPages] = useState(10);
  const [isOpen, setIsopen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(false);
  const [isAddStudentModalVisible, setIsAddStudentModalVisible] =
    useState<boolean>(false);
  const [classDropdownLoad, setClassDropdownLoad] = useState(false);

  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const delSubject = async (item: any) => {
    const payload = {
      studentId: item.studentId,
      subjectId: item.subjectId,
    };
    const res = await deleteStudentSubj(payload);

    if (res) fetchAllStudents();
  };

  const dataSource = studentData?.map((data: TeachersProps, index) => ({
    id: data?.id,
    key: index + 1,
    avatar: data?.imageUrl,
    salutation: data?.salutation,
    name: data?.firstName,
    Lastname: data?.LastName ?? data.lastName,
    class: data?.subjects?.length === 0 ? "-" : data?.className,
    subjects: data?.subjects,
    subjectNames: data?.subjects
      ?.map((subject) => subject?.subjectName)
      .join(", "),
    email: data?.email,
    number: data?.phoneNumber,
    progress: Number(data?.subjectsProgress).toFixed(0) || 0,
    classId: data?.classId,
  }));

  const columns = [
    {
      title: "First Name",
      dataIndex: "name",
      key: "name",
      width: "18%",
      render: (a: any, b: any) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={() =>
              navigate("/student-report", {
                state: { studentData: b },
              })
            }
          >
            <img
              src={b?.avatar ? b?.avatar : img}
              alt="avatar"
              style={{ width: "40px", height: "40px", borderRadius: "50px" }}
            />
            <p style={{ fontSize: "14px" }}>{a}</p>
          </div>
        );
      },
    },
    {
      title: "Last Name",
      dataIndex: "Lastname",
      key: "Lastname",
      width: "18%",
    },
    {
      title: "Class",
      dataIndex: "class",
      key: "class",
      width: "18%",
    },
    {
      title: "Subjects",
      dataIndex: "subjects",
      key: "subjects",
      width: "18%",
      render: (subjects: Subject[], record: any) =>
        subjects?.length === 0 ? (
          <Tag className="tooltip">0</Tag>
        ) : (
          <Tooltip
            title={
              subjects?.length > 0
                ? subjects?.map((item: Subject) => (
                    <Tag
                      key={item.id}
                      style={{
                        backgroundColor: "var(--primary-color)",
                        margin: "5px",
                        alignItems: "center",
                        display: "inline-flex",
                        gap: "3px",
                      }}
                    >
                      {item.subjectName}
                      <Popconfirm
                        title="Delete Subject"
                        description="Are you sure Delete Subject! ?"
                        onConfirm={(e) => {
                          //  e?.stopPropagation();
                          delSubject(item);
                        }}
                        okText="Yes"
                        cancelText="No"
                      >
                        <IoIosCloseCircleOutline
                          // onClick={() => delSubject(item)}
                          fontSize={"20px"}
                          cursor={"pointer"}
                        />
                      </Popconfirm>
                    </Tag>
                  ))
                : "-"
            }
          >
            <span className="tooltip">{subjects?.length}</span>
          </Tooltip>
        ),
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
      width: "18%",
      render: (_: any, record: any) => {
        return (
          <div>
            <Progress
              percent={record.progress}
              size="small"
              className="small-progress"
            />
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "10%",
      render: (_: any, record: any) => {
        return (
          <div
            style={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          >
            {checkRoutePermissions("updateStudent") && (
              <>
                <FiEdit2
                  onClick={() => {
                    setIsEdit(true);
                    setIsAddStudentModalVisible(true);
                    setData(record);
                  }}
                  className={`${user?.role === "teacher" && "hide_action"}`}
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                />
                <FiEdit
                  onClick={() => {
                    // setIsEdit(true);
                    // setIsModalOpen(true);
                    // setData(record);
                    console.log(record);
                    setIsopen(true);
                    setSingleStudentData(record);
                    setSingleStudentId(record?.id);
                  }}
                  className={`${user?.role === "teacher" && "hide_action"}`}
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                />
              </>
            )}

            {checkRoutePermissions("deleteStudent") && (
              <Popconfirm
                title="Delete Student"
                description="Are you sure to delete student ?"
                onConfirm={() => {
                  deleteStd(record.id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <IoTrashOutline
                  className={`${user?.role === "teacher" && "hide_action"}`}
                  style={{
                    width: "20px",
                    height: "20px",
                    color: "#D92D20",
                    cursor: "pointer",
                  }}
                />
              </Popconfirm>
            )}
            {/* {checkRoutePermissions("assignSubjects") &&
              record?.subjects?.length === 0 && (
                <MdAssignment
                  onClick={() => {
                    setIsopen(true);
                    setSingleStudentId(record?.id);
                  }}
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                />
              )} */}
          </div>
        );
      },
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fetchValue, setFetchValue] = useState([]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const onChange = (e: any) => {
    const findClass: any = fetchValue.find((item: any) => {
      return e === item.classId;
    });
    form.setFieldsValue({ subjects: [] });
    setSubjectValue([]);
    // setFetchValue(e);
    setClassesId(e);
    setSubjectList(findClass.subjects);
  };

  const deleteStd = async (id: number) => {
    try {
      const res = await deleteStudent(id);
      if (res?.status === 200) {
        notification.success({ message: "deleted successfully" });
        fetchAllStudents();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.setFieldsValue({ subjects: [] });
    setSubjectValue([]);
    setSubjectList([]);
    setClassValue(null);
    setClassesId("");
    setSubjectValue([]);
  };

  const fetchClasses = async () => {
    try {
      setClassDropdownLoad(true);
      if (user?.role === "teacher") {
        const res = await getAllClass();
        setClasses(res?.data.classes);
      } else {
        const res = await getAllClasses();
        setClasses(res?.data.records);
      }
      setClassDropdownLoad(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  const [form] = Form.useForm();

  const fetchAllStudents = async () => {
    try {
      setLoad(true);
      let result;
      if (user?.role === "teacher") {
        const payload = {
          page: currentPage,
          perPage: perPage,
          classId: classId,
          progress: true,
        };
        result = await getAllStudents(payload);
      } else {
        const payload = {
          page: currentPage,
          perPage: perPage,
          classId: classId,
          progress: true,
        };
        result = await getAllStudents(payload);
      }

      if (result) {
        setStudentData(result.lists);
        setCount(result.count ?? result.total);
      } else {
        console.error("No result returned from the API");
        setStudentData([]);
        setCount(0);
      }
    } catch (error) {
      console.error("Error fetching students:", error);
      setStudentData([]);
      setCount(0);
    } finally {
      fetchClasses();
      setLoad(false);
    }
  };

  const fetchByClass = async () => {
    try {
      setLoad(true);
      const payload = {
        page: 1,
        perPage: 10,
        classId: classId,
      };
      const payloads = {
        page: 1,
        perPage: 10,
        classId: classId,
        search: search,
      };
      if (user?.role === "teacher") {
        const res = await getAllStudents(search ? payloads : payload);
        setStudentData(res?.lists);
        setCount(res?.total);
      } else {
        const res = await getAllStudents(search ? payloads : payload);

        setStudentData(res?.lists);
        setCount(res?.count);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoad(false);
    }
  };

  const fetchSubject = async () => {
    setLoading(true);
    try {
      const res = await getSubject({ classId: classValue });
     

      setSubjectList(res?.data.subjects);
      setLoading(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const updateData = async () => {
    setLoaded(true);

    const formValues = form.getFieldsValue();
    const sendData = {
      //@ts-ignore
      studentId: data.id,
      classId: classesId,
      subjectIds: SubjectValue,
    };

    if (sendData.subjectIds.length === 0) {
      notification.error({
        message: "You have to select the subject to proceed further",
      });
      setLoaded(false);
      return;
    }
    try {
      const res = await assignStudents(sendData);
      if (res?.status === 200) {
        fetchAllStudents();
        setLoaded(false);
        handleCancel();
        setClassesId("");
      } else {
        setLoaded(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoaded(false);
    }
  };

  const selectValue = async (e: any) => {
   
    const payload = {
      page: currentPage,
      perPage: perPage,
      progress: true,
      passOut: e,
    };
    setLoad(true);
    const result = await getAllStudents(payload);
    if (result) {
      setStudentData(result.lists);
      setCount(result.count ?? result.total);
      setLoad(false);
    } else {
      console.error("No result returned from the API");
      setStudentData([]);
      setCount(0);
      setLoad(false);
    }
  };

  const debouncedFetchByClass = useCallback(debounce(fetchByClass, 1000), [
    search,
    classId,
  ]);

  useEffect(() => {
    if (search || classId) {
      debouncedFetchByClass();
    }
  }, [search, classId]);
  useEffect(() => {
    if (classId || search) {
      debouncedFetchByClass();
      return () => {
        debouncedFetchByClass.cancel();
      };
    } else {
      fetchAllStudents();
    }
  }, [search, classId, debouncedFetchByClass, currentPage]);

  useEffect(() => {
    fetchClasses();
  }, []);

  useEffect(() => {
    if (classValue) {
      fetchSubject();
    }
  }, [data, classValue]);


  useEffect(() => {
    const formValues = {
      name: `${data?.name} ${data?.Lastname}`,
      class: classValue ? classValue : data?.class,
    };

    if (!classValue) {
      //@ts-ignore
      formValues.subjects = data?.subjects?.map((subject) => subject.subjectId);
    }

    if (classValue) {
      //@ts-ignore
      formValues.subjects = undefined;
    }
    form.setFieldsValue(formValues);
  }, [data, form, classValue]);

 

  return checkRoutePermissions("studentPage") ? (
    <>
      <Modal
        open={isModalOpen}
        className="custom-modal"
        onCancel={handleCancel}
        footer={false}
      >
        <img src={addstudent} alt="logo" />
        <div className="modalheading">
          <h1>Edit Student</h1>
        </div>
        <div className="form">
          <Form
            form={form}
            name="validateOnly"
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item name="name" label="Student Name" className="labels">
              <Input
                //@ts-ignore
                // value={`dfsdfsdf`}
                type="text"
                disabled
                className="labelinput"
                placeholder="Name"
              />
            </Form.Item>

            <Form.Item name="class" label="Select a Class" className="labels">
              <SearchableDropdown
                onChange={onChange}
                setFetchedOptions={setFetchValue}
                url="/class/get-all"
                mode={false}
                placeholder="Search by class name"
                columns={["className"]}
                defaultValue={classesId}
                params={{ status: "active" }}
              />
            </Form.Item>

            {loading ? (
              <div style={{ fontSize: "14px", margin: "10px 0" }}>
                loading....
              </div>
            ) : (
              subjectList?.length > 0 && (
                <Form.Item
                  name="subjects"
                  label="Select Subjects"
                  className="labels"
                >
                  <Select
                    placeholder="Select Subjects"
                    optionFilterProp="children"
                    onChange={(e) => {
                      setSubjectValue(e);
                    }}
                    mode="multiple"
                    suffixIcon={
                      <RiExpandUpDownLine
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#667085",
                        }}
                      />
                    }
                  >
                    {subjectList?.length > 0 &&
                      subjectList?.map((item: any) => {
                        return (
                          <Select.Option
                            key={item?.subjectId}
                            value={item?.subjectId}
                          >
                            {item?.subjectName}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              )
            )}

            <Form.Item>
              <Space>
                <Button onClick={handleCancel} disabled={loaded} className="cancelbtn">
                  Cancel
                </Button>

                <Button
                  onClick={updateData}
                  disabled={loaded}
                  loading={loaded}
                  htmlType="submit"
                  className={`addbtn`}
                >
                  Update
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <StudentModal
        singleStudentId={singleStudentId}
        studentData={singleStudentData}
        isOpen={isOpen}
        setIsOpen={setIsopen}
        page={page}
        fetchAllStudents={fetchAllStudents}
        perPage={perPage}
      />
      <StudentSignupModal
        visible={isAddStudentModalVisible}
        onCancel={setIsAddStudentModalVisible}
        fetchStudents={fetchAllStudents}
        isEdit={isEdit}
        editable={data}
      />
      <div className="mainDiv responsive">
        <div className="mainss">
          <div className="main_parent">
            <div>
              <GHeader
                heading="Students Overview"
                numberOfPeople={`${count === undefined ? 0 : count} People`}
                buttonText="Add Student"
                onClick={() => {
                  setIsAddStudentModalVisible(true);
                  setIsEdit(false);
                }}
              />
              <div className="fliters_container">
                <Input
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  prefix={
                    <CiSearch
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#667085",
                      }}
                    />
                  }
                  className="filter_search"
                />
                <div className="select_container">
                  <Select
                    className="filter_select"
                    placeholder="Select Class"
                    notFoundContent={
                      classDropdownLoad ? (
                        <div style={{ margin: "5px 0px" }}>
                          <Loading />
                        </div>
                      ) : null
                    }
                    onChange={(e) => {
                      setClassId(e);
                    }}
                    optionFilterProp="children"
                    value={classId}
                    suffixIcon={
                      <RiExpandUpDownLine
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#667085",
                        }}
                      />
                    }
                  >
                    {classes?.length > 0 &&
                      classes?.map((item: any) => {
                        return (
                          <Select.Option value={item.classId}>
                            {item.className}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </div>
                {user?.role !== "teacher" && (
                  <Select
                    value={selectedValue}
                    style={{ width: 180, height: 45 }}
                    onChange={(e: any) => {
                      selectValue(e);
                      setSelectedValue(e);
                    }}
                    options={[
                      { value: true, label: "Not Enrolled" },
                      { value: false, label: "Enrolled" },
                    ]}
                  />
                )}
                <div>
                  <Button
                    className="btn_clear"
                    onClick={() => {
                      fetchAllStudents();
                      setClassId(null);
                      setSearch("");
                      setSelectedValue(false); // Reset to "Enrolled"
                      selectValue(false);
                    }}
                  >
                    Clear
                  </Button>
                </div>
                <div className="select_container">
                  {/* <Select
                    className="filter_select"
                    placeholder="All Students"
                    optionFilterProp="children"
                    onChange={onSelectChange}
                    suffixIcon={
                      <RiExpandUpDownLine
                        style={{ width: "20px", height: "20px", color: "#667085" }}
                      />
                    }
                    options={[
                      {
                        value: "1",
                        label: "Underperforming",
                      },
                      {
                        value: "2",
                        label: "Overperforming",
                      },
                    ]}
                  /> */}
                </div>
              </div>
            </div>
            {load ? (
              <Flex
                align="center"
                justify="center"
                style={{ height: "50vh" }}
                gap="middle"
              >
                <Spin size="large" />
              </Flex>
            ) : (
              <>
                <div className="main_tab" style={{ marginTop: "10px" }}>
                  <div className="table_container">
                    <Table
                      className="custom_table_design"
                      dataSource={dataSource}
                      columns={columns}
                      pagination={false}
                      scroll={{ x: 600 }}
                      rowKey={"id"}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="main_pagination">
              <CustomPagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                total={count}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <NotAccess />
  );
};

export default Teachers;
