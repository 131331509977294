// import { Card, Col, Tag } from 'antd'
// import React from 'react'
// import { CheckCircleOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
// import graphdown from "../../assets/graphdown.png";


// interface cardProps {
//     cardName: string;
//     graphImage: string;
//     data?: any;
//     type: string;
//     graphArrow: string
// }

// const ReportCard: React.FC<cardProps> = ({ cardName, graphImage, type, graphArrow, data }) => {
//     const className = data?.length > 0 ? data[0].subjectName : "Unknown Class";
//     const studentCount = data?.length;
//     const studentNames = data?.map((student: any) => student.name).join(', ');
//     return (
//         <Col xs={24} sm={12} lg={12}>
//             <Card className="custom_card">
//                 <div className="cardupdiv">
//                     <img src={graphImage} alt="graph" />
//                     <h1>{cardName}</h1>
//                 </div>
//                 <div className="classlabel">
//                     <p className="classtext">{className}</p>
//                 </div>
//                 <div className="carddowndiv">
//                     <h1>{studentCount ?? 12} Students</h1>
//                     <Tag
//                         icon={graphArrow === "down" ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
//                         color={type}
//                         style={{ borderRadius: "16px", padding: "4px" }}
//                     >
//                         23%
//                     </Tag>
//                 </div>
//             </Card>
//         </Col>
//     )
// }

// export default ReportCard


import { Card, Col, Tag, Tooltip } from 'antd';
import React from 'react';
import { CheckCircleOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import graphdown from "../../assets/graphdown.png";

interface cardProps {
    cardName: string;
    graphImage: string;
    data?: any;
    type: string;
    graphArrow: string;
}

const ReportCard: React.FC<cardProps> = ({ cardName, graphImage, type, graphArrow, data }) => {
    const className = data?.length > 0 ? data[0].className : "Unknown Class";
    const totalProgress = data?.length > 0 ? data[0].totalProgress??0 : 0;
    const studentCount = data?.length;
    const studentNames = data?.map((student: any) => student.name??student.studentName).join(', ');



    return (
        <Col xs={24} sm={12} lg={12}>
            <Card className="custom_card">
                <div className="cardupdiv">
                    <img src={graphImage} alt="graph" />
                    <h1>{cardName}</h1>
                </div>
                <div className="classlabel">
                    <p className="classtext">{className}</p>
                </div>
                <div className="carddowndiv">
                    {/* Tooltip for student names */}
                    <Tooltip title={studentNames ? studentNames : "No students"}>
                        <h1>{studentCount ?? 12} Students</h1>
                    </Tooltip>
                    <Tag
                        icon={graphArrow === "down" ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                        color={type}
                        style={{ borderRadius: "16px", padding: "4px" }}
                    >
                        {totalProgress}%
                    </Tag>
                </div>
            </Card>
        </Col>
    );
};

export default ReportCard;
