import Axios from "axios";

// export const base_url = "https://env-backend.herokuapp.com";
// export const base_url = "https://trackingbackend.herokuapp.com";
// export const base_url = "http://192.168.100.252:5000";
// export const base_url = 'https://192.168.100.11'
// export const base_url = 'http://192.168.18.15:9090'

// export const base_url = "http://192.168.100.35:9090";
export const base_url = "https://api.assabeel.app";
// 
// export const base_url = "http://192.168.100.99:9090";
// export const base_url = "http://192.168.100.10:9090";
// export const base_url = "http://192.168.18.23:9090";
// export const base_url = "http://13.53.197.253:9090";
// export const base_url = "http://localhost:9090";

// export const localAPI = "http://localhost:9090"
export const publicAPI = Axios.create({ baseURL: base_url });
export const getToken = () => localStorage.getItem("token");

export const isMobile = window.innerWidth < 767;
export const isTab = window.innerWidth <= 980;
export const privateAPI = Axios.create({
  baseURL: base_url,
  headers: {
    common: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  },
});

export const attachToken = async (token: string) => {
  const jwt = localStorage.getItem("token");
  privateAPI.defaults.headers.common.Authorization = `Bearer ${token || jwt}`;
  // console.log("Token Attached");
};
